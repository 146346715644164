export function useTelegram() {
  const tg = window.Telegram.WebApp;
  tg.MainButton.color = "#00B8D5";
  tg.MainButton.colorText = "#fff";
  tg.MainButton.text = "Показать заказ";

  function showMainButton() {
    tg.MainButton.show();
  }

  function hideMainButton() {
    tg.MainButton.hide();
  }

  function enableCloseConfirmation() {
    tg.enableClosingConfirmation();
  }

  function disableCloseConfirmation() {
    tg.disableClosingConfirmation();
  }

  function ready() {
    tg.ready();
  }

  return {
    mainButton: tg.MainButton,
    backButton: tg.BackButton,
    showMainButton,
    hideMainButton,
    disableCloseConfirmation,
    enableCloseConfirmation,
    ready,
    self: tg,
  };
}
