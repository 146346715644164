import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state() {
    return {
      offers: [],
      filters: [],
      cart: {},
      activeFilter: {},
      pageButtonVisible: false,
    };
  },
  getters: {
    cartIsNotEmpty: (state) => {
      return !!Object.keys(state.cart).length;
    },
  },
  mutations: {
    setOffers(state, payload) {
      state.offers = payload;
    },
    setFilters(state, payload) {
      state.filters = payload;
    },
    setActiveFilter(state, payload) {
      state.activeFilter = payload;
    },
    setPageButtonVisibility(state, payload) {
      state.pageButtonVisible = payload;
    },
  },
  actions: {
    async fetchData({ state, commit }) {
      try {
        const response = await axios.get("/letique/shop.json");
        if (response.status === 200) {
          const data = response.data;
          commit(
            "setFilters",
            data.categories.filter((item) => item.parent_id === 314)
          );
          commit("setActiveFilter", state.filters[0]);
          let offers = data.offers.filter(filterOffers);
          offers.sort(sorOffers(data));
          commit("setOffers", offers);
        }
      } catch {}
    },
    addToCart({ state }, id) {
      if (!state.cart[id]) {
        state.cart[id] = 0;
      }
      state.cart[id] += 1;
    },
    removeFromCart({ state }, id) {
      state.cart[id] -= 1;
      if (state.cart[id] <= 0) {
        delete state.cart[id];
      }
    },
  },
});

function sorOffers(data) {
  return function (a, b) {
    let index1 = data.main.indexOf(a.id);
    let index2 = data.main.indexOf(b.id);
    if (index1 === -1) {
      index1 = 1000;
    }
    if (index2 === -1) {
      index2 = 1000;
    }

    return index1 - index2;
  };
}

function filterOffers(offer) {
  return (
    offer.only_in_stores === false &&
    offer.hide === false &&
    offer.available === true
  );
}
