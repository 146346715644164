<template>
  <div v-if="loading" class="loader">
    <img src="@/assets/images/icons/logo.svg" alt="" class="loader__logo" />
    <div class="loader__container">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div v-else>
    <div class="layout d-flex flex-column">
      <router-view />
      <Footer />
    </div>
  </div>
</template>

<script setup>
import { ref, defineAsyncComponent, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useTelegram } from "./use/telegram";
const store = useStore();
const router = useRouter();
let tg = null;

const Footer = defineAsyncComponent(() => import("@/components/Footer"));
const pageButtonVisible = computed(() => store.state.pageButtonVisible);

let loading = ref(true);

const script = document.createElement("script");

script.onload = async () => {
  tg = useTelegram();
  tg.ready();
  tg.mainButton.color = "#00B8D5";
  tg.mainButton.colorText = "#fff";
  tg.mainButton.text = "Показать заказ";
  console.log(tg.self);
  if (
    !tg.self.initData ||
    !tg.self.initDataUnsafe.user ||
    !tg.self.initDataUnsafe.user.id
  ) {
    document.location.href = "https://letique.ru";
    return;
  }
  await store.dispatch("fetchData");
  loading.value = false;
};
script.onerror = () => {
  router.push({ name: "Error" });
  loading.value = false;
};

script.setAttribute("src", "https://telegram.org/js/telegram-web-app.js");
document.head.appendChild(script);

watch(
  () => pageButtonVisible.value,
  () => {
    if (pageButtonVisible.value) {
      tg.showMainButton();
      tg.enableCloseConfirmation();
    } else {
      tg.hideMainButton();
      tg.disableCloseConfirmation();
    }
  }
);
</script>
